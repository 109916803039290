/**
 * Created by mateimisarca on 24/09/2020
 */

import React, { Component } from 'react';
import Pagination from 'react-bootstrap-4-pagination';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { map, isEqual } from 'lodash';

import './pagination.scss';

export default class PaginationComponent extends Component {
  static propTypes = {
    totalPages: number,
    currentPage: number,
    showMax: number,
    size: string,
    threeDots: bool,
    prevNext: bool,
    onClick: func,
    onPerPageClick: func,
    itemsPerPage: arrayOf(number),
    perPage: number,
  };

  static defaultProps = {
    totalPages: 1,
    currentPage: 1,
    showMax: 3,
    size: 'sm',
    threeDots: true,
    prevNext: true,
    onClick: () => {
    },
    onPerPageClick: () => {
    },
  };

  renderPerPage() {
    const {
      itemsPerPage,
      perPage,
      onClick,
      totalItems,
    } = this.props;

    return (
      <div className="Pagination-perPage">
        <span>Elemente pe pagină:</span>
        <BootstrapPagination
          size="sm"
          onClick={ (evt) => evt.target.text && onClick({ perPage: evt.target.text }) }
        >
          { map(itemsPerPage, item => (
            <BootstrapPagination.Item
              key={ item }
              active={ isEqual(item, perPage) }
            >
              { item }
            </BootstrapPagination.Item>
          )) }
        </BootstrapPagination>
        { totalItems && <span>&nbsp;dintr-un total de <b>{ totalItems }</b> elemente</span> }
      </div>
    );
  }

  render() {
    const {
      onClick,
    } = this.props;
    return (
      <div className="Pagination">
        { this.renderPerPage() }
        <Pagination
          { ...this.props }
          className="Pagination-pagination"
          onClick={ page => onClick({ page }) }
        />
      </div>
    );
  }
}
