/**
 * Created by mateimisarca on 24/07/2018
 */

export default function anchors() {
  return ({dispatch, getState}) =>
    next => (action) => {

      if (getState() && getState().routing && getState().routing.location) {
        if (action.type === 'LoadingoverlayShowloadingscreen' && getState().routing.location.hash) {
          const id = getState().routing.location.hash.substr(1);
          window.scrollTo(0, document.getElementById(id).getBoundingClientRect().top);
        }
      }
      return next(action);
    };
}

