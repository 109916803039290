/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import logo from './../../assets/logo/totul-pentru-educatie-logo-v1.png';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
});

@connect(mapStateToProps)
export default class Footer extends Component {
  render() {
    const { courses } = this.props;
    return (
      <footer className="footer">
        <div className="footer-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-one">
                  <h2>Despre noi</h2>
                  <p><span style={ { color: '#C4B673FF' } }>totulpentrueducatie.ro</span> oferă servicii și
                    consultanță pentru mediul educațional - educatori,
                    profesori, consilieri școlari, directori, elevi -, punând accent pe aspectele practice ale predării,
                    învățării, evaluării școlare și formării profesionale.</p>
                  <p>De la dezvoltare de cursuri online pentru elevi și profesori, organizare de webinarii pe teme
                    educaționale și didactice, organizare de evenimente în școli, lansari de carte, până la servicii de
                    concepție, machetare și tipărire de cataloage, broșuri, flyere de informare educațională și
                    culturală, echipa noastră noastră va oferi mereu totul pentru educație!</p>
                  <Link to="/"><img src={ logo } alt="" style={ { width: '70%', backgroundColor: 'white' } } /></Link>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2>Cursurile noastre</h2>
                  <ul>
                    { courses.map((course, index) => (
                      <li key={ index }><Link to={ `/cursuri/${ course.link }` }>{ capitalize(course.title) }</Link>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-widget-menu">
                  <h2>Informatii utile</h2>
                  <ul>
                    <li><Link to="/termeni-si-conditii">Termeni, condiții și politică de confidențialitate</Link></li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="footer-wedget-four">
                  <h2>Contact</h2>
                  <div className="inner-box">

                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-envelope-o" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            <a href="mailto:info@totulpentrueducatie.ro" target="_top"
                               style={ { color: 'white' } }>info@totulpentrueducatie.ro</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-phone" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">

                            <a href="tel:0040741138739" style={ { color: 'white' } }>0741-138-739</a>
                            {/*<br />*/}
                            {/*<a href="tel:0040752548372" style={ { color: 'white' } }>0752-548-372</a>*/}

                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div className="inner-item">
                        <div className="media-left">
                          <span className="icon"><i className="fa fa-envelope-o" /></span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            <a href="https://forms.gle/LiX7cy5Pna2qtQpQ7" target="_blank"
                               style={ { color: 'white' } }>Contactează un formator</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="copy-right">
                  <p>Copyright 2022 &copy; <span>ReCreation - Perfecționare personală și profesională S.R.L.</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    );
  }
}
