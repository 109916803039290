/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Slider from './../../components/slider';
import HpAbout from './../../components/hp-about';
import Courses from './../../components/courses';
import Testimonials from './../../components/testimonials';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  courses: state.courses.courses,
});

@connect(mapStateToProps)
export default class HomePage extends Component {
  render() {
    const { courses, isLoggedIn } = this.props;
    console.log('@@@_ ', isLoggedIn);
    return (
      <div>
        <Slider />
        {/*<HpAbout />*/ }

        <Courses courses={ courses } isLoggedIn={ isLoggedIn } />
        <Testimonials />
      </div>
    );
  }
}
