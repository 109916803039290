/**
 * Created by mateimisarca on 20.03.2021
 */

import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { capitalize, includes, isEqual, map } from 'lodash';
import { Nav } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { menu } from './menu';

import './sidebar-nav.scss';

const mapDispatchToProps = dispatch => ({
  goTo: page => dispatch(push(page)),
});

@compose(
  withRouter,
  connect(null, mapDispatchToProps),
)
export default class SidebarNav extends Component {
  renderMenuItems(menu, oldPath) {
    const {
      website,
      location: {
        pathname,
      },
      profile,
      // ui: { [UI_IS_NAVIGATION_OPEN]: isNavigationOpen },
    } = this.props;
    const isNavigationOpen = true;

    return map(menu, menuItem => {
      if (!includes(menuItem.access, profile.role)) return null;

      let path = `${ oldPath }${ menuItem.path }`;
      if (isEqual(oldPath, '/websites') && website?.id) {
        path = `${ oldPath }/${ website.id }${ menuItem.path }`;
      }

      return (
        <Fragment key={ menuItem.path }>
          <Nav.Item className="SidebarNav-item" key={ path }>
            <Nav.Link className={ cx('SidebarNav-link', {
              'SidebarNav-link--folder': menuItem.children,
              'SidebarNav-link--active': isEqual(pathname, path),
            }) } eventKey={ path }>
              { menuItem.icon ? (
                <i className={ cx(menuItem.icon, 'mr-1 SidebarNav-icon') } />) : (menuItem.children ?
                <i className="mr-1 far fa-folder-open SidebarNav-icon" /> :
                <i className="mr-1 far fa-file SidebarNav-icon" />) }
              <span>{ capitalize(menuItem.slug) }</span>
            </Nav.Link>
          </Nav.Item>
          { isNavigationOpen && menuItem.children && this.renderMenuItems(menuItem.children, `${ oldPath }${ menuItem.path }`) }
        </Fragment>
      );
    });
  }

  render() {
    const { goTo } = this.props;
    const page = 'admin';
    return (
      <Nav
        variant="pills"
        className={ cx('flex-column container-fluid SidebarNav', {
          'SidebarNav--expanded': true,
        }) }
        activeKey={ page }
        onSelect={ (selectedKey) => goTo(selectedKey) }
      >
        <div className="SidebarNav-wrapper">
          { this.renderMenuItems(menu, '') }
        </div>
      </Nav>
    );
  }
}
