/**
 * Created by mateimisarca on 2019-06-15
 */

import React, { Component } from 'react';

import Heading from '../../components/heading';

import banner from '../../assets/images/banner_1920x350-1.jpg';

import WIP from './../../assets/images-t/wip.png';

import './webinarii-page.scss';

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/webinarii',
    text: 'Webinarii',
  },
];


export default class WebinariiPage extends Component {
  render() {
    return (
      <div className="partners">
        <Heading title="WEBINARII" links={ headingLinks } image={ banner } />

        <div className="traineri instructor-sec pt-100 pb-70">
          <div className="container">
            <div className="row" style={ {
              flexDirection: 'column'
            } }>

              <h2>în construcție</h2>
              <img src={ WIP } />

            </div>
          </div>
        </div>
      </div>
    );
  }
}
