/**
 * Created by matei on 20/11/2019
 */

import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { find, includes, findIndex, toLower, isEqual } from 'lodash';
import { bindActionCreators } from 'redux';

import CursViewModules from './curs-view-modules';

import { coursesActionsFacade as coursesActions } from '../../actions/courses';
import { resultsActionsFacade as resultsActions } from '../../actions/results';

import Module from './module/module';
import Heading from '../../components/heading';
import CursSuccessPage from '../curs-success-page';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  course: state.courses.course,
  modules: state.courses.modules,
  result: state.results.result,
  courses: state.courses.courses,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      coursesActions,
      resultsActions,
    ), dispatch),
  };
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class CursWrapper extends PureComponent {
  constructor(props) {
    super(props);

    const {
      match: {
        params,
      },
      location: {
        pathname,
      },
      history: {
        push,
      },
      user,
      actions: {
        getCourse,
        getModules,
      },
      courses,
      course,
    } = props;

    const currentStep = this.getStepIndex(pathname, course?.elements);

    const selectedCourse = find(courses, o => o.link === params.course);
    /*    const isSubscribed = find(user?.subscriptions, o => {
            return includes(toLower(o.cursuri), toLower(selectedCourse?.title)) ||
              (
                includes(selectedCourse?.title, 'Dicție și mișcare scenică') &&
                includes(o.cursuri, 'Dicție și mișcare scenică')
              ) || (
                isEqual(selectedCourse?.title, 'Utilizarea responsabilă a resurselor educaționale digitale') &&
                isEqual(o.cursuri, 'Resurse digitale pentru profesorii de gimnaziu')
              );
          },
        );*/


    getCourse(selectedCourse?.link).then(res => {
      getModules(res.payload?.course?._id);

      const isSubscribed = find(user?.subscriptions, o => isEqual(res.payload?.course?._id, o?.courseId));
      if (!isSubscribed) {
        push(`/cursuri/${ params.course }`);
      }

      this.setState({
        isSubscribed,
      });
    });


    this.state = {
      selectedCourse,
      isSubscribed: false,
      currentStep,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        pathname,
      },
      modules,
    } = this.props;

    const currentStep = this.getStepIndex(pathname, modules);

    this.setState({
      currentStep,
    });
  }

  getStepIndex(pathname, elements) {
    let lastPath = pathname.split('/');
    let idx = -1;

    for (let i = lastPath.length - 1; i >= 0; i--) {
      idx = findIndex(elements, o => isEqual(lastPath[i], o._id));
      if (idx >= 0) {
        return idx;
      }
    }

    return -1;
  }

  headingData() {
    const {
      match: {
        params,
      },
      location: {
        pathname,
      },
      course,
      modules,
    } = this.props;

    const paths = pathname.split('/');
    const module = find(modules, o => o._id === paths[3]);
    let image = null;
    if (module?.imageBanner) {
      try {
        image = require(`./../../assets/moduleImg/${ module.imageBanner }.jpg`);
      } catch (err) {
        image = null;
      }
    }

    let step = [];

    if (paths[3] && paths[3] === 'view-modules') {
      step = [{
        link: `/cursuri/${ params.course }/view-modules`,
        text: 'Module',
      }];
    } else if (paths[3] && paths[3] === 'success') {
      step = [{
        link: `/cursuri/${ params.course }/success`,
        text: 'Success',
      }];
    } else if (paths[3] && paths[3] !== 'view-modules') {
      step = [
        {
          link: `/cursuri/${ params.course }/view-modules`,
          text: 'Module',
        },
        {
          text: module?.title,
          link: `/cursuri/${ params.course }/${ paths[3] }`,
        },
      ];

      if (paths[4]) {
        step.push({
          text: find(module.elements, o => o.link === paths[4])?.title,
          link: `/cursuri/${ params.course }/${ paths[3] }/${ paths[4] }`,
        });
      }
    }

    const headingLinks = [
      {
        link: '/',
        text: 'Acasă',
      },
      {
        link: `/cursuri`,
        text: `Cursuri`,
      },
      {
        link: `/cursuri/${ params.course }`,
        text: course?.title,
      },
      ...step,
    ];

    return {
      title: `${ course?.title } - Module`,
      links: headingLinks,
      image,
    };
  }

  render() {
    const {
      match: {
        path,
        url,
        params,
      },
      location: { pathname },
      history: {
        push,
      },
      modules,
      result,
      actions: {
        getUserResults,
      },
      course,
    } = this.props;
    const { isSubscribed, currentStep } = this.state;

    if (
      !modules ||
      modules.length <= 0
    ) return null;

    const headingData = this.headingData();

    let nextId;
    if (result?.done) {
      const newId = findIndex(modules, o => o._id === result.moduleId);
      if (modules[currentStep + 1]) {
        nextId = modules[currentStep + 1]._id;
      }
    }

    return (
      <div>
        <Heading title={ headingData.title } links={ headingData.links } image={ headingData.image } />
        <div className="contact-page-sec aplicatie">
          <Switch>
            { course.hasSeries && !isSubscribed.modulesActive && (
              <Redirect to={ `/series/${ params.course }` } />
            ) }
            <Route
              path={ `${ path }/view-modules` }
              exact
              render={ (props) => (
                <div className="container">
                  <CursViewModules
                    { ...props }
                    isSubscribed={ isSubscribed }
                    modules={ modules }
                    parentPath={ url }
                    push={ push }
                    getUserResults={ getUserResults }
                  />
                </div>
              ) }
            />
            <Route path={ `${ path }/success` } component={ CursSuccessPage } />
            <Route
              path={ `${ path }/:moduleId` }
              render={ () => (
                <Module isSubscribed={ isSubscribed } />
              ) }
            />
          </Switch>
          { nextId && !includes(pathname, '/view-modules') && (
            <div
              style={ { display: 'flex', justifyContent: 'flex-end' } }
              className="single-input-fieldsbtn pb-70 container"
              onClick={ () => {
                const {
                  match: {
                    params,
                  },
                  history: {
                    push,
                  },
                } = this.props;
                push(`/cursuri/${ params.course }/${ nextId }`);
              } }
            >
              <input type="submit" value="Modulul următor" />
            </div>
          ) }
        </div>
      </div>
    );
  }
}
