/**
 * Created by mateimisarca on 29/11/2019
 */

import { required, minLength, maxLength } from './../../../lib/utils/validations';

const aplicatieValidation = (data) => {
  return {
    app: [required, minLength(30), maxLength(2400)],
  };
};

export default aplicatieValidation;

