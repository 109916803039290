/**
 * Created by matei on 2019-06-20
 */

import React, { Component } from 'react';

import Heading from '../../components/heading';

import banner from '../../assets/images/banner_1920x350-2.jpg';

import './termeni-si-conditii.scss';

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/termeni-si-conditii',
    text: 'Termeni, condiții și politică de confidențialitate',
  },
];

export default class TermeniSiConditiiPage extends Component {
  render() {
    return (
      <div className="termeniConditii">
        <Heading title="Termeni, condiții și politică de confidențialitate" links={headingLinks} image={banner} />

        <div className="termeniConditii-content pt-100 pb-70">
          <div className="container">
            <h1>Termeni, condiții și politică de confidențialitate</h1>

            <div className="termeniConditii-paragraph">
              <h2>1. REFERINȚE GENERALE</h2>
              <div>
                Prin utilizarea website-ului https://totulpentrueducatie.ro, sunteți de acord cu termenii și condițiile de
                utilizare. Termenii și condițiile se aplică tuturor utilizatorilor, inclusiv utilizatorilor care
                contribuie cu informații pe website.
              </div>
            </div>

            <div className="termeniConditii-paragraph">
              <h2>2. COPYRIGHT</h2>
              <div>
                Conținutul website-ului vă este oferit gratuit, cu scop informațional și educativ. Conținutul nu poate
                fi
                reprodus, vândut, licențiat, distribuit fără a avea în prealabil acordul scris al titularului dreptului
                de
                proprietate intelectuală. Sunteți de acord să nu folosiți, copiați, distribuiți conținutul acestui
                website
                în scop comercial. Website-ul https://totulpentrueducatie.ro își rezervă toate drepturile pentru conținutul
                publicat de către administratorii acestui website.
              </div>
            </div>

            <div className="termeniConditii-paragraph">
              <h2>3. UTILIZAREA WEBSITE-ULUI</h2>
              <div>
                <p>Scopul website-ului este de a informa, de a ajuta utilizatorii să aibă la dispoziție cât mai ușor,
                  structurat și rapid informațiile de care au nevoie. Termeni de utilizare:</p>

                <ul>
                  <li>website-ul poate fi utilizat în interes didactic;</li>
                  <li>nu aveți dreptul de a modifica ori degrada nicio parte din acest website;</li>
                  <li>nu veți publica pe acest website informații ce pot induce în eroare corectitudinea datelor (text,
                    materiale video, fotografii etc);
                  </li>
                  <li>veți respecta termenii și condițiile impuse de acest website.</li>
                </ul>
                <p>
                  În calitate de utilizator cu sau fără cont, sunteți de acord să nu folosiți niciun program, aplicație
                  sau orice alt mijloc de accesare automată a website-ului, în vederea spaming-ului, flood-ului,
                  furtului
                  de date, modificării sau alterării website-ului. Sunteți singurul răspunzător pentru activitatea
                  desfășurată pe acest website.
                </p>
              </div>
            </div>

            <div className="termeniConditii-paragraph">
              <h2>4. LIMITAREA RASPUNDERII</h2>
              <div>
                <p>TOTUL PENTRU EDUCAȚIE depune tot efortul pentru a se asigura că toate informațiile publicate pe acest web
                  site sunt corecte și actualizate. Totuși, https://totulpentrueducatie.ro nu este răspunzător pentru daunele
                  rezultate din greșeli, erori sau lipsa de acuratețe a materialelor publicate pe acest website.
                  Materialele sunt informative și didactice.</p>

                <p>TOTUL PENTRU EDUCAȚIE depune toate eforturile pentru a verifica și testa tot materialul publicat pe acest
                  website. Este întotdeauna înțelept ca utilizatorii să ruleze un program antivirus pe toate materialele
                  descărcate de pe Internet.</p>

                <p>TOTUL PENTRU EDUCAȚIE nu își asumă nicio responsabilitate pentru orice pierdere, întrerupere sau
                  deteriorare a datelor sau a sistemului dumneavoastră de computer care pot apărea în timp ce rulați
                  materialul de pe website-ul https://totulpentrueducatie.ro.</p>

              </div>
            </div>

            <div className="termeniConditii-paragraph">
              <h2>5. POLITICA DE CONFIDENȚIALITATE</h2>
              <div>
                <p>Toate informațiile furnizate de la si despre utilizatori sunt și vor rămâne confidențiale; website-ul
                  https://totulpentrueducatie.ro nu va folosi aceste informații în alte scopuri decât cele pentru care au
                  fost furnizate. https://totulpentrueducatie.ro poate folosi adresele de e-mail ale utilizatorilor pentru a
                  trimite oferte si noutăți despre programe, cursuri si alte activitati. Baza de date cu utilizatori
                  este pastrata in conditii de siguranta externe site-ului web si nu poate fi accesata online. Orice
                  persoana se poate dezabona de la primirea de oferte folosind formularele corespunzatoare.</p>

                <p>Deja ati fost informati ca incepand cu 25 mai 2018, a intrat in vigoare cea mai recenta reglementare
                  europeana cu privire la protectia datelor cu caracter personal, Regulamentul General privind Protectia
                  Datelor nr. 679/2016 („Regulamentul”). Va reasiguram de importanta pe care o acordam Datelor si
                  respectarii obligatiilor cu privire la securitatea acestora si dorim sa va informam asupra drepturilor
                  pe care le detineti, in conformitate cu noul Regulament.</p>

                <div className="termeniConditii-paragraph">
                  <h3>1. Cui ne adresam prin aceasta Politica ?</h3>
                  <div>
                    <p>Ne propunem sa va informam cu privire la prelucrarea de Date, pe care o facem in cazul in care
                      vizitati site-ul nostru, intrati in dialog cu noi in vederea achizitionarii de servicii, sunteti
                      deja client al nostru sau aveti ori va doriti sa colaborati cu noi.</p>

                    <p>Daca beneficiati de un serviciu oferit de noi, va putem prelucra Datele conform scopurilor si
                      temeiurilor reflectate in sectiunile de mai jos.</p>

                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>2. Ce DATE prelucram ?</h3>
                  <div>
                    <p>Dorim sa va oferim cele mai potrivite servicii, sa comunicam rapid si eficient, sa venim in
                      intampinarea nevoilor dumneavoastra. Trebuie totodata sa va oferim confortul sigurantei Datelor
                      dumneavoastra. Pentru toate acestea suntem obligati prin lege sau avem nevoie sa primim si sa
                      utilizam Date despre dumneavoastra, cum sunt:</p>

                    <h4>Date de identificare si date de contact:</h4>
                    <ul className="termeniConditii-paragraph">
                      <li>Nume, prenume, codul numeric personal, imaginea si celelalte date continute in actele de
                        identitate, cetatenia, adresele postale de domiciliu/ de corespondenta, semnatura olograf;
                      </li>
                    </ul>

                    <h4>Informatii legate de identificarea online a Clientului:</h4>
                    <ul className="termeniConditii-paragraph">
                      <li>Cookie, Adresa IP (Internet Protocol) al dispozitivului electronic folosit de Client;</li>
                    </ul>
                    <h4>Alte date si informatii cu caracter personal:</h4>
                    <ul className="termeniConditii-paragraph">
                      <li>Fotografii efectuate cu ocazia participarii la Cursuri si Workshop;</li>
                      <li>Date derivate din operatiuni de combinare, organizare sau extragere a datelor de mai sus;</li>
                      <li>Orice alte categorii de date pe care Clientii le furnizeaza catre noi sau pe care le dobandim
                        si le prelucram cu respectarea legii sau a contractelor aplicabile in relatia cu Clientii;
                      </li>

                    </ul>

                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>3. De ce prelucram date?</h3>
                  <div>
                    <p>Actvitatea noastra este special reglementata de lege in cele mai multe dintre aspectele sale, de
                      la identificarea dumeavoastra, confidentialitatea relatiei, prudenta si diligenta in prestarea
                      serviciilor, pana la masurile de securitate si chiar modul in care ne alegem si lucram cu
                      partenerii contractuali. Incheiem cu dumneavoastra contracte specifice si ne asumam obligatii in
                      legatura cu serviciile oferite. In afara de conditiile impuse de lege si contract noi prelucram
                      Datele primite de la dumneavoastra si in scop de marketing.</p>
                    <p>In vederea conformarii cu obligatiile legale, este necesar sa prelucram Date in scopuri
                      precum:</p>
                    <ul>
                      <li>Financiar contabile;</li>
                      <li>Administrarea portofoliului de clienti si gestiunea administrativ financiara;</li>
                      <li>Pastrarea/ depozitarea/ stocarea si arhivarea documentelor;</li>
                      <li>Implementarea masurilor de securitate a datelor si administrarea continuitatii activitatii in
                        cazul aparitiei unor situatii neprevazute, inclusiv prin realizarea de copii de siguranta;
                      </li>
                      <li>Implementarea unor mijloace care permit oricarei persoane sa semnaleze neconcordantele
                        sesizate in legatura cu serviciile oferite de noi;
                      </li>
                      <li>Indeplinirea obligatiilor de raportare si/sau analiza a informatiilor in conformitate cu
                        reglementarile fiscale.
                      </li>
                      <li>
                        <p>In urmarirea intereselor legitime pe care site-ul nostru le are in legatura cu buna
                          administrare a activitatii sale, efectuam prelucrari de Date in scopuri precum:</p>
                        <ul>
                          <li>Promovarea serviciilor oferite de TOTUL PENTRU EDUCAȚIE prin activitati de marketing online pe
                            Facebook, Instagram,Twitter, Linkedin, Google+, Youtube;
                          </li>
                          <li>Inregistrarea datelor de identificare online (cookie) obtinute in momentul conectarii
                            Clientului la pagina web https://totulpentrueducatie.ro;
                          </li>
                          <li>Mentinerea reputatiei, a integritatii si a securitatii afacerii;</li>
                          <li>Efectuarea de sondaje cu privire la serviciile oferite de TOTUL PENTRU EDUCAȚIE si activitatea
                            acesteia;
                          </li>
                        </ul>
                      </li>

                    </ul>
                    <p>Anumite prelucrari de Date le facem numai daca ne-ati dat acordul scris in acest sens. Va
                      solicitam acordul pentru a prelucra Datele dumneavoastra pentru scopuri precum:</p>
                    <ul>
                      <li>Transmiterea in mod direct de mesaje publicitare (marketing direct) in vederea promovarii
                        produselor si serviciilor pe care le consideram cele mai potrivite pentru Client sau pentru a
                        promova serviciile unor parteneri contractuali;
                      </li>
                      <li>Analiza comportamentului dumneavoastra cu prilejul accesarii site-ului
                        https://totulpentrueducatie.ro, prin folosirea de cookies;
                      </li>

                    </ul>
                    <p>
                      Daca v-am solicitat consimtamantul pentru prelucrarea Datelor dumneavoastra, va puteti razgandi in
                      orice moment prin anuntarea in scris pe e-mail sau telefonic. La momentul solicitarii
                      consimtamantului, vi se vor furniza si detaliile cu privire la scopul prelucrarii de Date si modul
                      simplu si concret in care va puteti retrage oricand acordul dat. Retragerea consimtamantului nu va
                      afecta legalitatea prelucrarii de Date facuta anterior retragerii.
                    </p>
                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>4. Cu cine impartasim datele ?</h3>
                  <div>
                    <p>Pentru a va putea oferi servicii la cele mai bune standarde, pentru a ne putea desfasura
                      activitatea, in urmarirea intereselor noastre legitime ori pentru ca suntem obligati prin lege,
                      este necesar sa dezvaluim sau sa transmitem Date catre anumite persoane sau entitati, cum ar
                      fi:</p>
                    <ul>
                      <li>Reprezentantii legali (de exemplu, tutore), ai persoanei vizate (copii);</li>
                      <li>Partneneri contractuali si prestatori de servicii cum ar fi: servicii IT si telecomunicatii;
                      </li>
                      <li>Agentia Nationala de Administrare Fiscala, in scopul transmiterii de informatii fiscale.</li>
                    </ul>
                    <p>In unele cazuri destinatarii Datelor au obligatia legala de pastrare a secretului profesional
                      (autoritatile). In celelalte cazuri, vom solicita contractual respectarea confidentialitatii
                      (furnizorii de servicii IT), inclusiv prin luarea unor masuri tehnice si organizatorice adecvate
                      pentru asigurarea securitatii Datelor. TOTUL PENTRU EDUCATIE nu va putea conditiona dezvaluirea Datelor
                      de luarea unor astfel de masuri si de asumarea unei obligatii de confidentialitate cand Datele
                      sunt dezvaluite in baza unei obligatii legale sau in apararea unui interes legitim.</p>

                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>5.Cat timp pastram DATELE ?</h3>
                  <div>
                    Avem nevoie sa prelucram Datele atat timp cat este necesar pentru a va oferi serviciile de care
                    beneficiati si in vederea realizarii scopurilor prezentate. Ulterior acestor momente trebuie sa
                    continuam prelucrarea Datelor in vederea conformarii cu obligatiile legale, inclusiv in scop de
                    arhivare. Termenele legale de arhivare sunt diferite, in functie de tipul de date. In general,
                    datele referitoare la plati contractuale trebuie pastrate 5 ani de la terminarea relatiei. Datele
                    pot fi pastrate insa o perioada mai mare de timp la solicitarea autoritatilor sau pentru protejarea
                    unor interese legitime.
                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>6. Implicatiile refuzului de a furniza DATE</h3>
                  <div>
                    <p>Puteti alege sa nu furnizati Date catre TOTUL PENTRU EDUCAȚIE, insa aceasta optiune poate determina
                      lipsa conformarii cu obligatiile noastre contractuale sau legale. Poate limita serviciile pe care
                      suntem capabili sa vi le oferim. Ne poate impiedica sa continuam a va furniza sau a va reinnoi
                      serviciile existente.</p>

                    <p>In cazul in care nu sunteti de acord cu prelucrarea Datelor in scop de marketing sau in orice alt
                      scop pentru care v-am solicitat in mod expres acordul, precum si in situatia in care va retrageti
                      acordul dat, relatia dumneavoastra contractuala cu cei de la TOTUL PENTRU EDUCAȚIE nu va suferi.</p>

                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>7. Drepturile PERSOANEI VIZATE</h3>
                  <div>
                    <p>In legatura cu prelucrarea exclusiv a Datelor dumneavoastra, beneficiati de unele drepturi pe
                      care le puteti exercita la cerere si in mod gratuit, in limitele si in masura indeplinirii
                      conditiilor legale, dupa cum urmeaza:</p>
                    <ul>
                      <li>Dreptul de acces la date – puteti verifica efectuarea prelucrarii Datelor dumneavoastra de
                        catre TOTUL PENTRU EDUCAȚIE, precum si detalii cu privire la prelucrare;
                      </li>

                      <li>Dreptul la rectificare – puteti solicita rectificarea Datelor inexacte sau suplimentarea
                        Datelor incomplete;
                      </li>

                      <li>Dreptul la stergerea datelor („dreptul de a fi uitat”) – pe care il puteti exercita in masura
                        in care sunt indeplinite conditiile prevazute de lege (de exemplu, daca Datele nu mai sunt
                        necesare scopului prelucrarii sau au fost prelucrate ilegal);
                      </li>

                      <li>Dreptul la restrictionarea prelucrarii – puteti solicita limitarea prelucrarii Datelor, de
                        exemplu in caz de contestare a legalitatii prelucrarii;
                      </li>

                      <li>Dreptul la portabilitatea datelor – ne puteti solicita ca Datele prelucrate prin mijloace
                        automate, pe baza de consimtamant sau in temeiul unui contract, sa vi le transmitem
                        dumneavoastra sau catre un alt operator de date, daca acest lucru este tehnic fezabil;
                      </li>

                      <li>Dreptul la opozitie – va puteti opune in orice moment, din motive intemeiate si legitime
                        legate de situatia dumneavoastra particulara, ca Datele dumneavoastra sa faca obiectul unei
                        prelucrari, de asemenea, va puteti opune in orice moment, in mod gratuit si fara nici o
                        justificare, ca Datele sa fie prelucrate in scop de marketing;
                      </li>

                      <li>Dreptul de a nu fi supus unei decizii individuale – puteti cere si obtine retragerea, anularea
                        sau reevaluarea oricarei decizii bazate exclusiv pe prelucrari efectuate prin mijloace automate
                        (incluzand crearea de profiluri) care produce efecte juridice cu privire la dumneavoastra sau va
                        afecteaza in mod similar, intr-o masura semnificativa;
                      </li>

                      <li>Dreptul de a depune o plangere in fata unei autoritati sau de a va adresa justitiei – aveti
                        dreptul de a va adresa cu plangere Autoritatii Nationale de Supraveghere a Prelucrarii Datelor
                        cu Caracter Personal, respectiv de a va adresa justitiei pentru apararea oricaror drepturi
                        garantate de legislatia aplicabila in domeniul protectiei datelor cu caracter personal, care au
                        fost incalcate, in masura in care considerati necesar.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>8. Cum sa ne contactati. Responsabilul cu protectia datelor</h3>
                  <div>
                    Va puteti exercita oricare dintre drepturile legale in legatura cu prelucrarea Datelor printr-o
                    cerere scrisa, datata si semnata olograf (de mana), transmisa in format hartie la sediul nostru din
                    str. Moeciu nr. 7A sau electronic prin e-mail, scanat, la info@totulpentrueducatie.ro.
                  </div>
                </div>

                <div className="termeniConditii-paragraph">
                  <h3>9. Modificari ale acestei Politici</h3>
                  <div>
                    Vom revizui periodic si vom actualiza aceasta Politica privind confidentialitatea ori de cate ori va
                    fi necesar. Politica actualizata va fi pusa la dispozitia dumneavoastra pe pagina web Actualizarea
                    politicii noastre va poate fi de asemenea anuntata prin SMS sau prin posta electronica.
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
