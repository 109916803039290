/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { upperCase, capitalize, includes } from 'lodash';

import slide1 from '../../assets/images-t/cover-fb-totul-pentru-educatie.jpg';
import slide2 from '../../assets/images-t/cover-fb-totul-pentru-educatie-v2.jpg';
import slide3 from '../../assets/images-t/cover-fb-totul-pentru-educatie-v3.jpg';
import logo from './../../assets/logo/totul-pentru-educatie-logo-v1.png';

const slides = [
  {
    title: logo,
    text: 'EDUCAȚIE • INOVAȚIE • CREATIVITATE',
    image: slide1,
    buttons: [
      {
        link: '/cursuri/adaptarea-activitatilor-online',
        text: 'Afla mai multe',
      },
      {
        link: '/inscriere/adaptarea-activitatilor-online',
        text: 'Inscrie-te',
      },
    ],
  },
  {
    title: '',
    text: '',
    image: slide2,
    buttons: [],
  },
  {
    title: '',
    text: '',
    image: slide3,
    buttons: [],
  },
];

export default class Slider extends Component {
  componentDidMount() {
    /* eslint-disable */
    $('.all-slide').owlCarousel({
      items: 1,
      nav: true,
      dots: true,
      autoplay: true,
      loop: true,
      navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
      mouseDrag: false,
      touchDrag: false,
    });

    $('.all-slide').on('translate.owl.carousel', function () {
      $('.slider-text h1').removeClass('animated fadeInUp').css('opacity', '0');
      $('.slider-text p').removeClass('animated fadeInDown').css('opacity', '0');
      $('.slider-text ul').removeClass('animated fadeInDown').css('opacity', '0');
    });

    $('.all-slide').on('translated.owl.carousel', function () {
      $('.slider-text h1').addClass('animated fadeInUp').css('opacity', '1');
      $('.slider-text p').addClass('animated fadeInDown').css('opacity', '1');
      $('.slider-text ul').addClass('animated fadeInDown').css('opacity', '1');
    });
    /* eslint-enable */
  }

  render() {
    return (
      <div className="slider">
        <div className="all-slide owl-item">
          { slides.map((slide, index) => (
            <div className="single-slide" style={ { backgroundImage: `url(${ slide.image })` } } key={ index }>
              <div className="slider-overlay" />
              <div className="slider-wrapper">
                <div className="slider-text">
                  <div className="slider-caption">
                    <h1>{ includes(slide.title, '/static/media/') ? <img style={{maxWidth: '400px'}} src={slide.title} /> : upperCase(slide.title) }</h1>
                    <p>{ slide.text }</p>
                    <ul>
                      { slide.buttons.map((button, btnIndex) => (
                        <li key={ btnIndex }>
                          <Link to={ button.link }>{ capitalize(button.text) }</Link>
                        </li>
                      )) }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )) }
        </div>
      </div>
    );
  }
}
