/**
 * Created by mateimisarca on 2019-06-07
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import CourseItem from './../course-item';

// import { courses } from '../../constants/courses';

import './courses.scss';

@withRouter
export default class Courses extends Component {
  componentDidMount() {
    /* eslint-disable */
    $('.all-course').owlCarousel({
      autoplay: false,
      pagination: false,
      nav: true,
      dots: true,
      items: 3,
      navText: ['<i class=\'fa fa-angle-left\'></i>', '<i class=\'fa fa-angle-right\'></i>'],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
      },
    });
    /* eslint-enable */
  }

  render() {
    const {
      courses, isLoggedIn, history: {
        push,
      },
    } = this.props;
    return (
      <div className="courses-wrapper">
        <div className="course-header-sec">
          <div className="course-sec-image" />
        </div>
        <div className="course-sec pb-70">
          <div className="container">

            <div className="row">
              <div className="col-md-12">
                <div className="sec-title">
                  <h1>Cursurile noastre</h1>
                </div>
              </div>
            </div>

            <div className="row">
              { isLoggedIn ? (
                <div className="course-list-sec">
                  <div className="all-course">
                    { courses.map((course, index) => (
                      <CourseItem key={ index } { ...course } />
                    )) }
                  </div>
                </div>
              ) : (
                <div className="course-list-sec">
                  <h2 style={ { position: 'relative', color: 'white' } }>Cursurile noastre sunt disponibile doar utilizatorilor logați
                    și care s-au înscris pentru aceste cursuri.</h2>
                  <div style={ {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  } }>
                    <div className="single-input-fieldsbtn" onClick={ (e) => {
                      e.preventDefault();
                      push('/login');
                    } }>
                      <input style={ { position: 'relative' } } type="submit" value="login" />
                    </div>
                  </div>
                </div>
              ) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
