/**
 * Created by mateimisarca on 20.03.2021
 */

export const menu = [
  {
    slug: 'serii',
    icon: 'fas fa-landmark',
    path: '/admin/series',
    access: ['admin', 'superAdmin'],
  },
  {
    slug: 'inregistrati',
    icon: 'fas fa-landmark',
    path: '/admin/subscriptions',
    access: ['admin', 'superAdmin'],
  },
  {
    slug: 'rezultate',
    icon: 'fas fa-landmark',
    path: '/admin/results',
    access: ['admin', 'superAdmin'],
  },
  {
    slug: 'cursuri',
    icon: 'fas fa-jedi',
    path: '/admin/courses',
    access: ['superAdmin'],
  },
];
