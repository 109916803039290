/**
 * Created by mateimisarca on 2019-06-12
 */

const NODE_ENV = process.env.NODE_ENV;
console.log('NODE_ENV ', NODE_ENV);

const config = {
  // apiURL: NODE_ENV === 'development' ? 'http://localhost:3060' : '//totulpentrueducatie.ro/api',
  apiURL: 'https://totulpentrueducatie.ro/api/',
  imagesURL: '//totulpentrueducatie.ro/images'
};

export default config;

