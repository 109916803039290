/**
 * Created by mateimisarca on 2019-06-12
 */

import React, { Component } from 'react';
import { join } from 'lodash';

import photoVirginia from './../../assets/images/virginia_alexe.jpg';
import photoLizetta from './../../assets/images/lizetta_mihaila.JPG';
import photoGabriela from './../../assets/images/gabriela_barbulescu.jpg';
import photoAdina from './../../assets/images/adina_tatu.jpg';

import './traineri-page.scss';
import Heading from '../../components/heading';
import banner from '../../assets/images/banner_1920x350-2.jpg';

const trainers = [
  {
    name: 'Adina Tatu',
    details: [
      'profesor metodist', 'consilier școlar', 'formator pentru educația adulților', 'mentor',
    ],
    photo: photoAdina,
  },
  {
    name: 'Gabriela Bărbulescu',
    details: [
      'Psiholog școlar, formator pentru formarea continuă a profesorilor',
    ],
    photo: photoGabriela,
  },
  {
    name: 'Virginia Alexe',
    details: [
      'Formator pentru formarea continuă a profesorilor, coach',
    ],
    photo: photoVirginia,
  },
  {
    name: 'Lizetta Mihăilă',
    details: [
      'Psiholog specialist în psihologie educațională și consiliere vocațională',
    ],
    photo: photoLizetta,
  },
];

const headingLinks = [
  {
    link: '/',
    text: 'Acasa',
  },
  {
    link: '/traineri',
    text: 'Formatori',
  },
];

export default class TraineriPage extends Component {
  render() {
    return (
      <div>
        <Heading title="Formatori" links={ headingLinks } image={ banner } />

        <div className="traineri instructor-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              { trainers.map((trainer, index) => (

                <div className="col-md-3 col-sm-6 col-xs-12" key={ index }>
                  <div className="instructor-member">
                    <div className="instructor-member-thumb">
                      <img src={ trainer.photo } alt="" />
                      <div className="instructor-overlay">
                        <ul>
                          { join(trainer.details, ', ') }
                        </ul>
                      </div>
                    </div>
                    <h2>{ trainer.name }</h2>
                  </div>
                </div>
              )) }

            </div>
          </div>
        </div>
      </div>
    );
  }
}
