/**
 * Created by mateimisarca on 23/11/2019
 */

import * as request from './../lib/utils/request';
import config from './../config';

import { COURSES } from './../constants';

function getCourses() {
  return {
    types: [COURSES.getCourses, COURSES.getCoursesSuccess, COURSES.getCoursesFail],
    promise: request.get(`${config.apiURL}/courses`),
  };
}

function getCourse(course) {
  return {
    types: [COURSES.getCourse, COURSES.getCourseSuccess, COURSES.getCourseFail],
    promise: request.get(`${config.apiURL}/courses/course?course=${course}`),
  };
}

function getModules(course) {
  return {
    types: [COURSES.getModules, COURSES.getModulesSuccess, COURSES.getModulesFail],
    promise: request.get(`${config.apiURL}/modules/modules?course=${course}`),
  };
}

function getSeries(getSeries) {
  return {
    types: [COURSES.getSeries, COURSES.getSeriesSuccess, COURSES.getSeriesFail],
    promise: request.get(`${config.apiURL}/courses/series?series=${getSeries}`),
  };
}

export const coursesActionsFacade = {
  getCourses,
  getCourse,
  getModules,
  getSeries
};
