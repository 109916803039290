/**
 * Created by mateimisarca on 27.03.2021
 */

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { find, isEqual } from 'lodash';

import { coursesActionsFacade as coursesActions } from '../../actions/courses';

import './series-page.scss';

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  series: state.courses.series,
  courses: state.courses.courses,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(Object.assign({},
      coursesActions,
    ), dispatch),
  };
}

@compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)
export default class Series extends Component {
  constructor(props) {
    super(props);

    const {
      actions: {
        getCourse,
        getSeries,
      },
      match: {
        params,
      },
      user,
      courses,
    } = this.props;

    const selectedCourse = find(courses, o => o.link === params.course);

    getCourse(selectedCourse.link).then(res => {
      const isSubscribed = find(user?.subscriptions, o => isEqual(res.payload?.course?._id, o?.courseId));
      getSeries(isSubscribed?.seriesId);

      this.setState({
        isSubscribed,
      });
    });

    this.state = {
      isSubscribed: null,
    };
  }

  render() {
    const { isSubscribed } = this.state;
    const {
      match: {
        params,
      },
      series,
    } = this.props;

    if (isSubscribed?.seriesId) {
      return (
        <div className="series error-page-sec pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <div className="error-page-content">
                  <h1>Felicitări</h1>
                  <h2>Pentru acest curs este necesara participarea la seminarul online.</h2>
                  <p>Ati fost asignat la seminarul { series?.title }</p>
                  <div className="series-icon fa fa-exclamation-circle" />
                  <Link to={ `/cursuri/${ params.course }` }>Inapoi la curs</Link>
                </div>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="series error-page-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="error-page-content">
                <h1>Felicitări</h1>
                <h2>Pentru acest curs este necesara participarea la seminarul online.</h2>
                <p>Odată stabilita data seminarului veti fi notificat</p>
                <div className="series-icon fa fa-exclamation-circle" />
                <Link to={ `/cursuri/${ params.course }` }>Inapoi la curs</Link>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    );
  }
}
