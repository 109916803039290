/**
 * Created by mateimisarca on 2019-06-11
 */

import incheiat from '../assets/images/bullet-incheiat.png';
import activ from '../assets/images/bullet-activ.png';
import curand from '../assets/images/bullet-curand.png';
import inscriereActiva from '../assets/images/bullet-inscriere-activa.png';
import inactiv from '../assets/images/bullet-inactiv.png';
import inPregatire from '../assets/images/bullet-in-pregatire.png';

import curs1 from './../assets/images/banner_420x230_1.jpg';
import curs2 from './../assets/images/banner_420x230_2.jpg';
import curs3 from './../assets/images/banner_420x230_3.jpg';
import curs4 from './../assets/images/banner_420x230_4.jpg';
import curs5 from './../assets/images/banner_420x230_5.jpg';
import curs6 from './../assets/images/banner_420x230_6.jpg';
import curs7 from './../assets/images/banner_420x230_7.jpg';
import curs8 from './../assets/images/banner_420x230_8.jpg';

import bannerCursDictie from './../assets/images/banner_1920x350-4.jpg';
import banner from './../assets/images/banner_1920x350-1.jpg';

export const courses = [
  {
    _id: '616e5f6b1455141296c18814',
    title: 'Strategii de prevenire a bullyingului',
    texts: [
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de MEC și dezvoltat în parteneriat cu Casa Corpului Didactic București.' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul preuniversitar.',
      },
    ],
    details: [
      {
        title: 'CUI SE ADRESEAZĂ',
        text: 'Tuturor cadrelor didactice din învățământul preuniversitar.',
      },
      {
        title: 'SCOP',
        text: 'Cursul are ca scop Abilitarea cadrelor didactice din învățământul preuniversitar pentru prevenirea bullyingului.',
      },
      {
        title: 'DURATĂ',
        text: 'Cursul are o durată de 24 de ore și se va desfășura în sistem blended learning: 6 ore webinar sincron și 18 ore online individual. După parcurgerea cu succes a tuturor modulelor, profesorii vor primi adeverințe emise de Casa Corpului Didactic București, care vor servi la completarea dosarului de formare profesională a cadrelor didactice.',
      },
      {
        title: 'COMPETENȚE VIZATE',
        text: '<ul>' +
          '<li>Cunoașterea caracteristicilor specifice ale bullyingului;</li>' +
          '<li>Conștientizarea efectelor bullyingului la nivelul individului și al comunității;</li>' +
          '<li>Identificarea modalităților de prevenire a bullyingului.</li>' +
          '</ul>',
      },
      {
        title: 'CALENDAR',
        text: '<b>1 – 30 octombrie 2022</b>:  Înscrierea la curs<br />' +
          '<b>2 – 5 noiembrie 2022</b>: repartizarea pe serii pentru întâlnirile sincron',
      },
      {
        title: 'ORGANIZATOR SI PARTENERI',
        text: 'Cursul este avizat de MEC și dezvoltat în parteneriat cu Casa Corpului Didactic București. <br />' +
          'Înscrierea și participarea la curs sunt gratuite',
      },
    ],
    durata: '24 ore',
    link: 'bullying',
    detalii: {
      trainer: '',
      materiale: '',
      teste: '',
      perioadaInscriere: '1 – 30 octombrie 2022',
      locuri: '500',
      durata: '24 ore',
      pret: 'gratuit',
    },
    bullet: activ,
    activ: true,
    courseImg: curs8,
    banner: banner,
  },
];

export const bullets = {
  'incheiat': 'assets/images/bullet-incheiat.png',
  'activ': 'assets/images/bullet-activ.png',
  'curand': 'assets/images/bullet-curand.png',
  'inscriereActiva': 'assets/images/bullet-inscriere-activa.png',
  'inactiv': 'assets/images/bullet-inactiv.png',
  'inPregatire': 'assets/images/bullet-in-pregatire.png',
};
